
	/* ================================================
		1. Common Elements
	================================================ */

		*{
			margin: 0;
			padding: 0;
			-webkit-box-sizing: border-box; 
			-moz-box-sizing: border-box; 
			box-sizing: border-box;
		}
		body {
			height: 100vh;	
			overflow: hidden;
			text-align: left;
			line-height: 1.5;
			background: #fff;
		}
		.app{
			overflow: hidden;
		}

	/* ================================================
		2. Drawing Elements
	================================================ */

		.drawing-elements{
			height: 100vh;
			width: 80px;
			background: #fafafa;
			position: fixed;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 99;
			border-right: solid 1px #f0f0f0;
		}
		.drawing-elements ul{
			border: solid 1px #f0f0f0;
	        position: relative;
		}
		.drawing-elements ul li{
			list-style: none;
			text-align: center;
	        cursor: pointer;
	        position: relative;
	        border-bottom: solid 1px #f0f0f0;
		}
		.drawing-elements ul li:last-child{
			border-bottom: none;
		}
		.drawing-elements ul li,
		.drawing-elements .width{
			-webkit-transition: all 0.2s;
	        -moz-transition: all 0.2s;
	        -o-transition: all 0.2s;
	        -ms-transition: all 0.2s;
	        transition: all 0.2s;
		}
		.drawing-elements ul li:hover{
			background: #f0f0f0;
		}
		.drawing-elements ul li.active{
			background: #3498db;
		}
		.drawing-elements ul li .tool{
			padding: 10px;
		}
		.drawing-elements ul li img{
			height: 20px;
			width: auto;
			margin: 0 auto;
			display: block;
		}
		.drawing-elements ul li.active > .tool img{
			-webkit-filter: invert(0) sepia(0) saturate(1) hue-rotate(0deg) brightness(5);
			-moz-filter: invert(0) sepia(0) saturate(1) hue-rotate(0deg) brightness(5);
			-o-filter: invert(0) sepia(0) saturate(1) hue-rotate(0deg) brightness(5);
			-ms-filter: invert(0) sepia(0) saturate(1) hue-rotate(0deg) brightness(5);
			filter: invert(0) sepia(0) saturate(1) hue-rotate(0deg) brightness(5);
		}
		.drawing-elements ul li ul{
			position: absolute;
			left: 50px;
			background: #FFF;
			top: 0;
	        overflow: hidden;
		}
		.drawing-elements ul li ul li{
			display: flex;
			padding: 0;
		}
		.drawing-elements .width{
			height: 40px;
			width: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
		}
		.drawing-elements ul li ul li:hover{
			background: transparent;
		}
		.drawing-elements .width:hover{
			background: #fafafa;
		}
		.drawing-elements .width.active{
			background: #f0f0f0;
		}
		.drawing-elements .width .options{
			background: #000;
			-webkit-border-radius: 100%;
	        -moz-border-radius: 100%;
	        -o-border-radius: 100%;
	        -ms-border-radius: 100%;
	        border-radius: 100%;
		}
		.drawing-elements .width .options.pixel-1{
			height: 3px;
			width: 3px;
		}
		.drawing-elements .width .options.pixel-2{
			height: 6px;
			width: 6px;
		}
		.drawing-elements .width .options.pixel-3{
			height: 9px;
			width: 9px;
		}
		.drawing-elements .width .options.color-option{
			height: 20px;
			width: 20px;
			border: solid 1px rgba(0,0,0,.11);
		}
		.drawing-elements .width input[type="color"]{
			height: 40px;
			width: 40px;
			position: absolute;
			-webkit-opacity: 0;
			-moz-opacity: 0;
			filter: alpha(opacity=0);
			-khtml-opacity: 0;
			-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
			opacity: 0;
			cursor: pointer;
		}

	/* ================================================
		3. Canvas
	================================================ */

		.canvas-holder{
			width: -webkit-calc(100% - 80px);
	        width: -moz-calc(100% - 80px);
	        width: -o-calc(100% - 80px);
	        width: -ms-calc(100% - 80px);
	        width: calc(100% - 80px);
			float: right;
			height: 100vh;
			position: relative;
			overflow: scroll;
		}
		.canvas-holder canvas{
			background: #ffffff;
			position: absolute;
		}





